<template>
  <div>
    <div v-if="is_weixin" class="is_weixin_box">
      当前为微信浏览器，无法进行正常安装！<br />
      请点击右上角【在默认浏览器中打开】
    </div>
    <div class="top_img"></div>

    <!-- <div>
        {{ url }}
      </div> -->

    <div class="logo_div">
      <img class="logo" src="@/assets/img/index/logo1.png" alt="" />
      <div class="app_name">大象</div>
      <div class="version">
        版本号：v{{ version }}
      </div>
      <div class="app_status_txt">
        <i class="iconfont iconanzhuo adicon1"></i>适用于 Android 设备
      </div>
    </div>
    <div class="button_box">
      <div class="downbutton2" v-if="is_weixin">
        <i class="iconfont iconanzhuo"></i>
        无法安装
      </div>

      <div class="downbutton" @click="click_anzhang" v-else>
        <i class="iconfont iconanzhuo"></i>
        开始安装
      </div>
    </div>
  </div>
</template>

<script>
import { request, open_new_page } from "@/utils/http.js";
import { Base64 } from "js-base64";

export default {
  data() {
    return {
      is_weixin: true,
      version_id: "",
      download_url: "",
      version: "-",
    };
  },
  mounted() {
    this.is_weixin = this.isWeiXin();

    var query = this.$route.query;
    this.version_id = query.version_id;
    if (this.version_id) {
      var that = this;
      setTimeout(function () {
        that.get_download_url();
      }, 100);
    }

    // var download_str = decodeURIComponent(download_url);
    // var url = Base64.decode(download_str);
    // console.log(url)
    // this.url = url;
  },
  methods: {
    get_download_url() {
      let obj = {
        version_id: this.version_id,
      };
      var that = this;
      request("post", process.env.VUE_APP_AUTH_HOST + "/app/download_android", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            that.download_url = res.data.data.url;
            that.version = res.data.data.version;
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    click_anzhang() {
      console.log(this.download_url);
      if (this.download_url) {
        open_new_page(this.download_url);
      } else {
        this.$Message.info("下载还没准备好");
      }
    },

    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.version {
  text-align: center;
  font-size: 14px;
  color: #ccc;
}

.top_img {
  background-size: 100% auto;
  height: 60px;
  background-repeat: repeat-x;
  background-image: url("@/assets/img/app/top_bg_mobile.png");
}

.is_weixin_box {
  margin: 0px 0 10px 0;
  padding: 10px 20px;
  background: #ff5656;
  text-align: center;
  color: #fff;
}

.adicon1 {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.app_status_txt {
  margin-top: 15px;
  justify-content: center;
}

.downbutton {
  width: 150px;
  height: 40px;
  border-radius: 100px;
  background: #4466dd;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
}

.downbutton:active {
  background: #0d325c;
}

.downbutton2 {
  width: 150px;
  height: 40px;
  border-radius: 100px;
  background: #9a9a9a;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
}

.downbutton2:active {
  background: #bababa;
}

.button_box {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.app_name {
  color: #333;
  font-size: 18px;
  margin-top: 30px;
}

.logo_div {
  margin-top: 80px;
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
</style>
